@import "animate.css";
@import "~loaders.css/src/animations/pacman.scss";

:root {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color-scheme: light dark;
  color: black;
  background-color: rgb(246, 245, 245);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --primary-color: #646cff;
  --accent-color: #646cff;
  --primary-dark: #161616;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
html {
  scroll-behavior: smooth;
}
a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none;
}
html {
  overflow: -moz-scrollbars-none;
  overflow-x: hidden;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 30px;
  border: 1px solid transparent;
  padding: 0.6em 2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  margin: 10px 0;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
  background: transparent;
  color: inherit;
}
button:focus,
button:focus-visible {
  outline: none;
}
/*
@media (prefers-color-scheme: light) {
	:root {
		color: #213547;
		background-color: #ffffff;
	}
	a:hover {
		color: #747bff;
	}
	button {
		background-color: #f9f9f9;
	}
} */

img,
picture {
  display: block;
  width: 100%;
}

ul,
li {
  list-style: none;
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/helvetica-neu.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}
img:not(.home-img) {
  pointer-events: none;
}
@font-face {
  font-family: "Aurore";
  src: url("./assets/fonts/LaBelleAurore.woff2") format("woff2"),
    url("./assets/fonts/LaBelleAurore.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Coolvetica";
  src: url("./assets/fonts/CoolveticaRg-Regular.woff2") format("woff2"),
    url("./assets/fonts/CoolveticaRg-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.btn-disabled {
  cursor: no-drop;
}

// /* width */
// ::-webkit-scrollbar {
//   width: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #7f7f7f;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   border-radius: 9px;
//   background: #555;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #2a2a2a;
// }

::-webkit-scrollbar {
  display: none !important;
}
@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

p {
  cursor: default;
}
span.tag {
  font-size: 20px;
  color: #09c47c;
  font-family: Aurore;
}
.highlight {
  font-size: 30px;
  line-height: normal;
  font-family: Aurore;
}
h1,
h2,
h3,
h4,
h5,
h6,
span {
  cursor: default;
}

@media screen and (max-width: 1200px) {
  body {
    overflow-x: hidden;
  }
}

@keyframes shimmer {
  0% {
    background-position: -50rem top; /*50px*/
  }
  70% {
    background-position: 12.5rem top; /*200px*/
  }
  100% {
    background-position: 12.5rem top; /*200px*/
  }
}
.loading-shimmer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: grey;
  background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.3, #ffffff), to(#acacac));
  background-position: -50rem top; /*50px*/
  background-repeat: no-repeat;
  // -webkit-background-clip: text;
  // background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 1s infinite;
  // -webkit-background-size: 50rem 100%; /*50px*/
  // font-size: 90px;
}
.leaflet-container {
  border: 1px solid black;
  margin: 20px auto;
  width: 100%;
  height: 50vh;
  z-index: 1;
}

span.focus {
  font-weight: 500;
  color: var(--primary-color);
}
