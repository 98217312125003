.single-skill-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 40px;
  // padding: 30px;
  width: min(350px, 90%);
  .up {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .indicator {
    width: 100%;
    height: 10px;
    margin-top: 5px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    background: rgb(83, 83, 83);
    .innerindicator {
      overflow: hidden;
      border-top-right-radius: 20px 40px;
      width: 0%;
      transition: all 0.3s ease-in-out;
      height: inherit;
    }
  }
}

@media screen and (max-width: 1200px) {
  .single-skill-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
}
