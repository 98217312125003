.horizontal-box {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 999;
  pointer-events: none;
  border-radius: 0;
  //   visibility: hidden;
  background: rgb(176, 176, 176);
  width: 100vw;
  .inner {
    transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    overflow: hidden;
    background: #26ade9;
    width: 0%;
    position: relative;
    height: 4px;
  }
}

.bottom {
  background: rgb(176, 176, 176);
  //   visibility: visible;
  display: none;
}

@media screen and (max-width: 1200px) {
  .horizontal-box {
    position: fixed;
    top: 0;
    z-index: 1000;
    .inner {
      height: 2px;
    }
  }
}
