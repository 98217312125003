.btm-container {
  //   background: red;
  color: var(--primary-color);
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;

  p {
    width: min(1000px, 80%);
    text-align: center;
  }
}
