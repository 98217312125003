.work-container {
  min-height: 100vh;
  // background-color: var(--white);
  background: white;
  display: flex;
  align-items: center;
  background-image: url("../../assets/svgs/zig.svg");
  background-size: 40vw;
  // background-repeat: no-repeat;
  justify-content: center;
  padding: 100px 0;
  .center {
    // background: white;
    padding: 20px 50px;
    .title {
      text-align: center;
      margin-bottom: 20px;
      transform: translateY(-50px);
      background: white;
      width: fit-content;
      margin: auto;
      padding: 20px;
      font-size: 80px;
      color: transparent;
      -webkit-text-stroke-color: var(--primary-dark);
      letter-spacing: 2px;
      -webkit-text-stroke-width: 1px;
    }
    // display: flex;

    max-width: 1400px;
    margin: auto;
    .swiper {
      padding: 50px;
      .box {
        border: 1px solid grey;
        padding: 50px;
        background: white;
        border-radius: 10px;
        img {
          width: 100%;
          height: 140px;
          margin: 10px auto;
          object-fit: contain;
          pointer-events: none;
        }
        h3 {
          pointer-events: none;
          font-size: 30px;
          font-family: Aurore;
          letter-spacing: 1px;
          text-align: center;
          text-transform: capitalize;
          padding: 10px 0;
          pointer-events: none;
        }
        p {
          pointer-events: none;
          font-family: Helvetica;
          margin-bottom: 20px;
        }
        h5 {
          pointer-events: none;
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .work-container {
    min-height: 70vh;
    overflow: hidden;
    background-repeat: no-repeat;
    padding: 0;
    .center {
      .title {
        text-align: center;
        margin-bottom: 20px;
        transform: translateY(30px);
        background: white;
        width: fit-content;
        margin: auto;
        padding: 0;
        font-size: clamp(2.5rem, 2.5vw, 5rem);
        color: transparent;
        -webkit-text-stroke-color: var(--primary-dark);
        letter-spacing: 2px;
        -webkit-text-stroke-width: 1px;
      }
    }
  }
}
