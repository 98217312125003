.parent {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .home-container {
    overflow-x: hidden;
    min-height: fit-content;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    .draw {
      position: absolute;
      width: 400px;
      right: 200px;
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1000px;

      .info {
        font-size: 20px;
        font-family: Aurore;
        color: crimson;
      }
      .info.end {
        margin-left: 20px;
      }
      .highlights {
        color: #646cff;
        font-size: 12px;
        font-weight: 600;
      }
      .fname {
        color: crimson;
        font-weight: 800;
        font-size: 80px;
      }
      .img {
        margin-right: 50px;
        img {
          width: 100%;
          margin-right: 50px;
          border-radius: 50%;
          object-fit: cover;
          transition: all 0.5s ease;
          box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.5);

          &:hover {
            border-radius: 20px;
          }
          &::after {
            content: "Heyy";
            position: absolute;
            color: white;
          }
        }
      }
      .details {
        flex-grow: grow;
        text-align: justify;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .home-container {
      padding: 100px 20px 20px 20px;
      .center {
        flex-direction: column;
        margin: auto;
        .img {
          margin: auto;
        }
        .info {
          font-size: 20px;
          font-family: Aurore;
          padding: 0 10px;
        }
        .highlights {
          color: #646cff;
          font-size: 12px;
          font-weight: 600;
        }
        .fname {
          color: crimson;
          font-weight: 800;
          font-size: 50px;
        }
        .details {
          text-align: left;
        }
      }
    }
  }
}
