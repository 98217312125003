.skill-container {
  .custom-shape-divider-top-1661079542 {
    pointer-events: none;
    top: 0;
    overflow: hidden;
    left: 0;
    width: 100%;
    line-height: 0;
  }

  .custom-shape-divider-top-1661079542 svg {
    position: relative;
    display: block;
    width: calc(131% + 1.3px);
    height: 64px;
  }

  .custom-shape-divider-top-1661079542 .shape-fill {
    fill: #161616;
  }
  // background: rgb(139, 139, 139);
  background: #121212;
  background-image: url("../../assets/svgs/curves.svg");
  background-size: 5vw, 70vw;
  background-position: top right;
  background-repeat: no-repeat;

  min-height: 40vh;
  // color: var(--primary-dark);
  color: white;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 100px 0;

    .title {
      text-align: center;
      font-family: Helvetica;
      margin-bottom: 50px;
    }
    .skill-container {
      max-width: 80vw;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .skill-container {
    .center {
      width: 100%;
      .title {
        font-size: 30px;
        .highlight {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
      .skill-container {
        max-width: fit-content;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      }
    }
  }
}

@media screen and (max-width: 395px) {
  .skill-container {
    .center {
      width: 100%;
      .title {
        font-size: 30px;
        .highlight {
          font-size: 15px;
          margin-bottom: 20px;
        }
      }
      .skill-container {
        max-width: fit-content;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      }
    }
  }
}
