.footer-container {
  min-height: 50vh;
  padding: 100px 0;
  background: #161616;
  color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .flex-bottom {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: white;
      img {
        width: 50px;
        fill: white;
      }
      p {
        font-size: 15px;
      }
    }
    .socials {
      margin: 50px auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .single {
        width: 50px;
        height: 50px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .bottom-footer {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 2px;
      }
    }
  }
}
.m-icon {
  font-size: 30px;
}
.social-icon {
  font-size: 30px;
  color: white;

  &:hover {
    color: #8389ff;
  }
}

@media screen and (max-width: 1200px) {
  .footer-container {
    padding: 100px 0;
    text-align: center;
    .flex-bottom {
      width: 95%;
      margin: auto;
    }
  }
}
